<!--
 * @Description: 创建项目
 * @Author: ChenXueLin
 * @Date: 2021-09-10 11:22:16
 * @LastEditTime: 2022-04-21 15:35:13
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 -->
          <template>
            <!-- 基本信息 start -->
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <div class="detail-info">
                <el-form
                  label-width="120px"
                  ref="addForm"
                  label-position="right"
                  :model="addForm"
                  :rules="addFormRules"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="项目名称" prop="projectName">
                        <el-input
                          placeholder="项目名称"
                          v-model="addForm.projectName"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="负责人" prop="projectLeaderId">
                        <!-- <e6-vr-select
                          v-model="addForm.projectLeaderId"
                          :data="engineerList"
                          placeholder="负责人"
                          title="负责人"
                          clearable
                          :props="{
                            id: 'employeeId',
                            label: 'employeeName'
                          }"
                        ></e6-vr-select> -->
                        <all-user-search
                          v-model="addForm.projectLeaderId"
                          clear
                          placeholder="负责人"
                          title="负责人"
                          :propsConfig="{
                            id: 'employeeId',
                            label: 'employeeName'
                          }"
                        >
                        </all-user-search>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="联系电话" prop="phoneNo">
                        <el-input
                          placeholder="联系电话"
                          v-model="addForm.phoneNo"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        label="关单核算时间"
                        prop="closeCalculateTime"
                      >
                        <el-date-picker
                          v-model="addForm.closeCalculateTime"
                          placement="bottom-start"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="关单核算时间"
                          popper-class="special"
                        ></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="备注" prop="remark">
                        <el-input
                          placeholder="备注"
                          v-model="addForm.remark"
                          maxlength="100"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!-- 基本信息 end -->
          </template>
          <!-- 关联任务单start -->
          <section class="table-wrapper">
            <div class="table-box">
              <detail-table-title :showSearch="false">
                <template v-slot:title>
                  关联任务单
                </template>
                <template v-slot:btns>
                  <el-button type="primary" @click="addTask">
                    添加关联任务单
                  </el-button>
                </template>
              </detail-table-title>
            </div>
            <el-table
              border
              height="500px"
              :data="selectRows"
              highlight-current-row
              ref="elTable"
            >
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in columnData"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="120"
              >
                <template slot-scope="scope">
                  <e6-td-operate
                    :data="getOperateList()"
                    @command="handleOperate($event, scope.row)"
                  ></e6-td-operate>
                </template>
              </el-table-column>
            </el-table>
          </section>

          <!-- 关联任务单end -->
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
        <!-- 查看 -->
      </section>
    </div>
    <!-- 添加关联任务单弹框  start -->
    <add-associated-order
      :addOrderDialog="addOrderDialog"
      @handleClose="handleClose"
      @bindOrder="bindOrder"
    ></add-associated-order>
    <!-- 添加关联任务单弹框  end  -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPageReszie from "@/mixins/list-page-resize";
import { bindTaskColumnData } from "./columnData";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import addAssociatedOrder from "./components/addAssociatedOrder";
import { getEngineer, createProject } from "@/api";
import { printError } from "@/utils/util";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "addProject",
  data() {
    return {
      loading: false,
      engineerList: [], //工程师列表
      addForm: {
        phoneNo: "",
        projectLeaderId: "",
        projectName: "",
        remark: "",
        closeCalculateTime: ""
      },
      addFormRules: {
        projectName: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: ["blur", "change"]
          }
        ],
        projectLeaderId: [
          {
            required: true,
            message: "请选择项目负责人",
            trigger: ["blur", "change"]
          }
        ],
        phoneNo: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: ["blur", "change"]
          }
        ],
        closeCalculateTime: [
          {
            required: true,
            message: "请选择关单核算时间",
            trigger: ["blur", "change"]
          }
        ]
      },
      itemIdList: [], //关联的任务单
      columnData: bindTaskColumnData, //表头
      selectRows: [],
      addOrderDialog: false
    };
  },
  mixins: [base, listPageReszie],
  components: { DetailTableTitle, addAssociatedOrder, allUserSearch },
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [getEngineer({})];
        let [engineerRes] = await Promise.all(promiseList);
        this.engineerList = this.getFreezeResponse(engineerRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //创建
    submit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (!this.selectRows.length) {
            this.$message.warning("请至少关联一个任务单");
            return;
          }
          this.createProject();
        }
      });
    },
    //查询全部调度信息
    async createProject() {
      try {
        this.loading = true;
        let res = await createProject({
          ...this.addForm,
          itemIdList: this.selectRows.map(item => {
            return item.workOrderId;
          })
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.handleGoBack();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 点击取消
    handleGoBack() {
      this.closeTag(this.$route);
      this.routerPush({
        name: "operationsCenter/projectManage",
        params: {
          refresh: false
        }
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "查看任务单",
          id: 1
        },
        {
          label: "删除",
          id: 2
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        //查看任务单,任务单详情
        this.routerPush({
          name: "taskListManage/detail",
          params: {
            refresh: true,
            workOrderId: row.workOrderId
          }
        });
      }
      if (val.id == 2) {
        this.selectRows = this.selectRows.filter(item => {
          return item.workOrderId != row.workOrderId;
        });
      }
    },
    //添加关联任务单
    addTask() {
      this.addOrderDialog = true;
    },
    //绑定任务单
    bindOrder(data) {
      // this.selectRows = data;
      let selectRows = this.selectRows;
      this.selectRows = this.unionObjects(selectRows, data);
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    unionObjects(arr1, arr2) {
      // 合并两个数组并创建一个Set来去除重复项
      let unipArr = arr1.concat(
        arr2.filter(
          itemB =>
            arr1.findIndex(itemA => itemA.workOrderId == itemB.workOrderId) ==
            -1
        )
      );
      return unipArr;
    },
    //查询全部任务单
    handleClose(name) {
      this[name] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  .detail-info {
    padding: 20px 20px 0px 0px;
  }
}
.btn-box {
  padding: 15px;
  box-sizing: border-box;
}
</style>
